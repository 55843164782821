const images = {
  ecommerce: [
    require("../assets/e-commerce/1.avif"),
    require("../assets/e-commerce/2.avif"),
    require("../assets/e-commerce/3.avif"),
  ],
  eduardo: [
    require("../assets/eduardo/11.avif"),
    require("../assets/eduardo/22.avif"),
    require("../assets/eduardo/33.avif"),
    require("../assets/eduardo/55.avif"),
    require("../assets/eduardo/44.avif"),
  ],
  omega: [
    require("../assets/omega/111.avif"),
    require("../assets/omega/222.avif"),
    require("../assets/omega/333.avif"),
  ],
  roque: [
    require("../assets/roque/1111.avif"),
    require("../assets/roque/2222.avif"),
    require("../assets/roque/3333.avif"),
  ],
  roxana: [
    require("../assets/roxana/11111.avif"),
    require("../assets/roxana/22222.avif"),
    require("../assets/roxana/33333.avif"),
    require("../assets/roxana/44444.avif"),
  ],
  sebacar: [
    require("../assets/sebacar/111111.avif"),
    require("../assets/sebacar/222222.avif"),
    require("../assets/sebacar/333333.avif"),
    require("../assets/sebacar/444444.avif"),
  ],
};

export default images;
