import React from "react";
import perfil1 from "../assets/perfil1.avif";

const backgroundText = [
  'I need a platform to showcase my "innovative work".',
  'Can you design a software to optimize "collaborative efforts"?',
  'Looking for assistance in building an e-commerce site for my "brand".',
  'We want to digitalize and improve our "internal processes".',
  'I need a tool to better track and manage our "resources".',
  'We are looking for a mobile solution to engage our "community".',
  'Can you create a system to monitor "sales trends" and "performance"?',
  'I need a user-friendly tool to manage "appointments" and "meetings".',
  'We want a modern, mobile-first website optimized for "all screens".',
  'I’m searching for a platform to visualize and interpret "business data".',
  'Can you integrate a reliable "payment gateway" for our "store"?',
  'I need a CRM solution to improve our "client relations" and "engagement".',
  'I’m interested in developing a custom SaaS solution for our "industry".',
  'We need an efficient tool to organize and manage "employee schedules".',
  'I want to improve my site’s "search ranking" to increase "visibility".',
  'We’re seeking a flexible online booking system for our "appointments".',
  'Can you build a chatbot to handle "customer inquiries" instantly?',
  'We need an online marketplace to connect "buyers and sellers".',
  'I’m looking for a secure "user authentication" system to protect our "data".',
  'Can you create a subscription-based model for our "premium users"?',
];

const SobreMi = () => {
  return (
    <section
      id="About me"
      className="py-5 d-flex justify-content-center align-items-center"
      style={{
        background: "orange",
        position: "relative",
        overflow: "hidden",
        minHeight: "75vh",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 0,
          color: "rgba(0.1, 1, 0.1, 0.1)",
          fontFamily: '"Courier New", monospace',
          fontSize: "3.1rem",
          textAlign: "justify",
          whiteSpace: "pre-wrap",
          pointerEvents: "none",
          lineHeight: "1.5",
        }}
      >
        {backgroundText.join(" ")}
      </div>

      <div
        className="container position-relative"
        style={{
          zIndex: 1,
        }}
      >
        <div className="row justify-content-center">
          <div className="col-12 text-center mb-4">
            <div
              style={{
                position: "relative",
                display: "inline-block",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  background: "orange",
                  filter: "blur(2px)",
                  zIndex: -1,
                }}
              ></div>
              <h2
                className="display-1 display-sm-4 display-md-5 display-lg-2"
                style={{
                  fontWeight: "700",
                  fontFamily: '"Courier New", monospace',
                  fontSize: "5.5rem",
                  color: "whitesmoke",
                  marginBottom: "0.5rem",
                  lineHeight: "1.2",
                  textTransform: "uppercase",
                  textShadow: `0 0 10px orange,
                              2px 2px 4px rgba(0, 0, 0, 0.9),
                              0 0 15px rgba(255, 165, 0, 0.8),
                              0 0 20px rgba(255, 140, 0, 0.6),
                              0 0 30px rgba(255, 140, 0, 0.5)`,
                }}
              >
                About Me
              </h2>
            </div>
          </div>

          <div className="col-12 col-md-8 col-lg-6 d-flex justify-content-center">
            <div
              className="card d-flex flex-column flex-lg-row p-4 rounded"
              style={{
                boxShadow: "6px 6px rgba(0, 0, 0, 0.9)",
                borderRadius: "8px",
                textAlign: "center",
                width: "100%",
                backgroundColor: "#ffffff",
              }}
            >
              <div
                className="d-flex justify-content-center align-items-center mb-4 mb-lg-0"
                style={{
                  marginRight: "16px",
                }}
              >
                <img
                  loading="lazy"
                  src={perfil1}
                  alt="Profile"
                  className="rounded-circle"
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "cover",
                    borderRadius: "50%",
                    boxShadow: "3px 3px rgba(0, 0, 0, 0.2)",
                  }}
                />
              </div>

              <div className="d-flex flex-column align-items-start">
                <p
                  className="text-muted"
                  id="me"
                  style={{
                    fontSize: "0.8rem",
                    lineHeight: "1.6",
                    fontWeight: "bold",
                    letterSpacing: "0.5px",
                    maxWidth: "800px",
                    margin: "0",
                    paddingLeft: "16px",
                    textAlign: "left",
                  }}
                >
                  Hi, I’m a passionate developer specializing in innovative
                  technological solutions. With expertise in JavaScript, React,
                  and Node.js, I focus on creating responsive designs,
                  developing web applications, and building scalable custom
                  software. Let’s collaborate and bring your next project to
                  life. 🚀
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SobreMi;
