import React, { useState, useEffect, useCallback } from "react";
import { Navbar, Container, Button } from "react-bootstrap";
import HamburgerMenu from "../Components/HamburgerMenu";
import "../css/style.css";

const FloatingBar = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);

  const handleScroll = useCallback(() => {
    const currentScrollY = window.scrollY;
    const headerSection = document.getElementById("me");

    if (headerSection && currentScrollY < headerSection.offsetHeight) {
      setIsVisible(false);
    } else if (currentScrollY < lastScrollY) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }

    setLastScrollY(currentScrollY);
  }, [lastScrollY]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  return (
    <>
      <div
        className={`position-fixed top-0 start-50 translate-middle-x w-85 ${
          isVisible ? "fade-in" : "fade-out"
        }`}
        style={{
          transition: "opacity 0.5s ease, transform 0.5s ease",
          opacity: isVisible ? 1 : 0,
          transform: isVisible ? "translateY(0)" : "translateY(-20px)",
          background: "#1c1c1c",
          zIndex: 22,
          borderBottom: "2px solid #333",
          borderTop: "2px solid #333",
          borderRadius: "25px",
          margin: "10px 0",
          padding: "8px 16px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
          maxWidth: "1200px",
          width: "calc(100% - 40px)",
          left: "20px",
          pointerEvents: isVisible ? "auto" : "none",
        }}
      >
        <Navbar expand="lg" className="p-2 d-flex align-items-center">
          <Container className="d-flex justify-content-between">
            <Navbar.Brand href="#" className="text-white">
              <h6
                className="mb-0"
                style={{
                  fontSize: "1rem",
                  letterSpacing: "1px",
                  textShadow: "4px 4px rgba(0, 0, 0, 0.5)",
                  fontFamily: '"Courier New", monospace',
                  fontWeight: "bold",
                }}
              >
                &lt;Eduardo Cabral/&gt;
              </h6>
              <strong>
                <code
                  style={{
                    fontSize: "0.9rem",
                    background:
                      "linear-gradient(to right, #FF7F50, #FFD700, #32CD32, #1E90FF, #8A2BE2)",
                    WebkitBackgroundClip: "text",
                    color: "transparent",
                    fontWeight: "bold",
                  }}
                >
                  &lt;Software Developer/&gt;
                </code>
              </strong>
            </Navbar.Brand>

            <div className="d-none d-md-flex align-items-center justify-content-center flex-grow-1">
              <Button
                variant="link"
                className="text-white mx-2 hover-underline"
                href="#Home"
                style={{
                  textDecoration: "none",
                  fontSize: "1rem",
                }}
              >
                Home
              </Button>
              <Button
                variant="link"
                className="text-white mx-2 hover-underline"
                href="#About me"
                style={{
                  textDecoration: "none",
                  fontSize: "1rem",
                }}
              >
                About me
              </Button>
              <Button
                variant="link"
                className="text-white mx-2 hover-underline"
                href="#Proyects"
                style={{
                  textDecoration: "none",
                  fontSize: "1rem",
                }}
              >
                Projects
              </Button>
            </div>

            <Button
              variant="light"
              onClick={() => setMenuOpen(!menuOpen)}
              className="ms-auto"
              style={{
                fontSize: "1.2rem",
                width: "35px",
                height: "35px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "50%",
                border: "none",
                backgroundColor: "#1c1c1c",
                padding: "0",
                color: "#fff",
              }}
            >
              {menuOpen ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  style={{ width: "24px", height: "24px" }}
                >
                  <path
                    d="M6 18L18 6M6 6l12 12"
                    stroke="currentColor"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 25 25"
                  fill="currentColor"
                  style={{ width: "25px", height: "25px" }}
                >
                  <path
                    d="M4 6h16"
                    stroke="currentColor"
                    strokeWidth="2.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10 12h10"
                    stroke="currentColor"
                    strokeWidth="2.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M4 18h16"
                    stroke="currentColor"
                    strokeWidth="2.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </Button>
          </Container>
        </Navbar>
      </div>

      <HamburgerMenu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
    </>
  );
};

export default FloatingBar;
