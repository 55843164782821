import React, { useMemo } from "react";
import {
  FaReact,
  FaJs,
  FaHtml5,
  FaCss3Alt,
  FaNode,
  FaNpm,
  FaAngular,
  FaGit,
  FaGithub,
  FaGitlab,
  FaDocker,
  FaDev,
  FaNodeJs,
  FaAws,
  FaVuejs,
} from "react-icons/fa";
import "../css/style.css";

const AnimatedBackground = React.memo(() => {
  const icons = useMemo(() => {
    return Array.from({ length: 20 }).map((_, index) => {
      const Icon = [
        FaReact,
        FaJs,
        FaHtml5,
        FaCss3Alt,
        FaNode,
        FaNpm,
        FaAngular,
        FaGit,
        FaGithub,
        FaGitlab,
        FaDocker,
        FaDev,
        FaNodeJs,
        FaAws,
        FaVuejs,
      ][Math.floor(Math.random() * 15)];

      const topPosition = `${8 + Math.random() * 80}%`;
      const leftOrRight =
        Math.random() < 0.5
          ? `${Math.random() * 50}%`
          : `${50 + Math.random() * 50}%`;

      return (
        <Icon
          key={index}
          className="background-icon"
          style={{
            top: topPosition,
            left: leftOrRight,
            animationDuration: `${5 + Math.random() * 10}s`,
            animationDelay: `${Math.random() * 5}s`,
          }}
        />
      );
    });
  }, []);

  return <div className="animated-background">{icons}</div>;
});

export default AnimatedBackground;
