import React, { useState } from "react";
import "./css/style.css";
import Header from "./Components/Header";
import Proyectos from "./Components/Proyectos";
import Habilidades from "./Components/Habilidades";
import Timeline from "./Components/Timeline";
import Contacto from "./Components/Contacto";
import Footer from "./Components/Footer";
import SobreMi from "./Components/SobreMi";
import FloatingBar from "./Components/FloatingBar";
import PrivacyPolicy from "./utils/PrivacyPolicy";
import "bootstrap/dist/css/bootstrap.min.css";

const App = () => {
  const [currentPage, setCurrentPage] = useState("home");

  React.useEffect(() => {
    const scrollBehavior = document.documentElement.style;
    scrollBehavior.scrollBehavior = "smooth";
    return () => (scrollBehavior.scrollBehavior = "auto");
  }, []);

  return (
    <div>
      {currentPage === "home" ? (
        <>
          <Header />
          <FloatingBar />
          <SobreMi />
          <Proyectos />
          <Habilidades />
          <Timeline />
          <Contacto />
          <Footer setCurrentPage={setCurrentPage} />
        </>
      ) : (
        <PrivacyPolicy setCurrentPage={setCurrentPage} />
      )}
    </div>
  );
};

export default App;
