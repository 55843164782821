import React, { useState } from "react";
import "../css/style.css";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { FaGraduationCap, FaStar, FaGlobe } from "react-icons/fa";
import { Button } from "react-bootstrap";
import devschoolacademia from "../assets/devschoolacademia.avif";
import codigoencasa from "../assets/codigoencasa.avif";
import egglive from "../assets/egglive.avif";
import ImageButton from "../utils/ImageButton";
import Certificadoegg from "../assets/Certificadoegg.avif";
import Certificadocodigo from "../assets/Certificadocodigo.avif";
import CertificadoDevSchool from "../assets/CertificadoDevSchool.avif";
import ModalCertificate from "./ModalCertificate";
import AnimatedBackground from "./AnimatedBackground";

const Timeline = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalImage, setModalImage] = useState(null);

  const handleShowModal = (image) => {
    setModalImage(image);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <section
      id="Credentials"
      className="main-background timeline-section py-16"
      style={{ backgroundColor: "transparent", marginTop: "6rem" }}
    >
      <AnimatedBackground />
      <div className="container mx-auto px-4 text-center">
        <h2
          className="text-white display-2 display-sm-5 display-md-6 display-lg-3"
          style={{
            fontWeight: "700",
            fontFamily: '"Courier New", monospace',
            marginBottom: "0.5rem",
            lineHeight: "1.2",
          }}
        >
          Certifications & Skills
        </h2>

        <h3
          className="display-sm-4 display-md-5 display-lg-2 mb-5"
          style={{
            background:
              "linear-gradient(to right, #FF7F50, #FFD700, #32CD32, #1E90FF, #8A2BE2)",
            WebkitBackgroundClip: "text",
            color: "transparent",
            fontWeight: "bold",
            
          }}
        >
          Efficiency and continuous skill improvement.
        </h3>

        <VerticalTimeline animate={true} layout="2-columns" lineColor="#3a4c62">
          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            date="2024"
            dateClassName="timeline-date"
            iconStyle={{ background: "#1e2a38", color: "#fff" }}
            icon={<FaGlobe />}
            contentStyle={{
              border: "1px solid #e0e0e0",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              background: "rgba(249, 249, 249, 0.90)",
            }}
          >
            <h3
              className="vertical-timeline-element-title"
              style={{
                textAlign: "left",
                color: "#1e2a38",
                fontWeight: "600",
              }}
            >
              Independent Professional
            </h3>
            <p
              style={{
                textAlign: "left",
                color: "#495a6b",
                fontSize: "0.9rem",
                textWrap: "balance",
              }}
            >
              Bringing creativity and technical expertise to diverse projects.
              With 10 months of experience as a Fullstack Developer, I
              specialize in building scalable, efficient solutions using modern
              technologies like React, Node.js, and MongoDB.
            </p>
          </VerticalTimelineElement>

          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            date="2023"
            dateClassName="timeline-date"
            iconStyle={{ background: "#1e2a38", color: "#fff" }}
            icon={<FaGraduationCap />}
            contentStyle={{
              border: "1px solid #e0e0e0",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              background: "rgba(249, 249, 249, 0.90)",
            }}
          >
            <h3
              className="vertical-timeline-element-title"
              style={{
                textAlign: "left",
                color: "#1e2a38",
                fontWeight: "600",
              }}
            >
              Certification
            </h3>
            <h4
              className="vertical-timeline-element-subtitle mt-1"
              style={{
                textAlign: "left",
                color: "#3e4c59",
                fontWeight: "500",
              }}
            >
              DevSchool Academy
            </h4>
            <p
              style={{
                textAlign: "left",
                color: "#495a6b",
                fontSize: "0.9rem",
                textWrap: "balance",
              }}
            >
              <strong>
                Completed a comprehensive Full Stack Web Developer course at
                DevSchool, demonstrating expertise in web development,
                programming, database management, and deploying full-stack
                applications using modern frameworks and tools.
              </strong>
            </p>
            <div className="d-flex align-items-center mt-2">
              <Button
                variant="link"
                className="minimal-button"
                onClick={() => handleShowModal(CertificadoDevSchool)}
                style={{
                  color: "#1e88e5",
                  fontWeight: "bold",
                  marginRight: "10px",
                }}
              >
                View Certificate
              </Button>
              <ImageButton
                src={devschoolacademia}
                alt="DevSchool"
                className="img-fluid"
                style={{ width: "35px", borderRadius: "50%"}}
                link="https://devschool.com.ar/"
              />
            </div>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            date="2022"
            dateClassName="timeline-date"
            iconStyle={{ background: "#1e2a38", color: "#fff" }}
            icon={<FaGraduationCap />}
            contentStyle={{
              border: "1px solid #e0e0e0",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              background: "rgba(249, 249, 249, 0.90)",
            }}
          >
            <h4
              className="vertical-timeline-element-title"
              style={{
                textAlign: "right",
                color: "#1e2a38",
                fontWeight: "600",
              }}
            >
              Certification
            </h4>
            <h5
              className="vertical-timeline-element-subtitle mt-1"
              style={{
                textAlign: "left",
                color: "#3e4c59",
                fontWeight: "500",
              }}
            >
              Bootcamp Real First Edition
            </h5>
            <p
              style={{
                textAlign: "left",
                color: "#495a6b",
                fontSize: "0.9rem",
                textWrap: "balance",
              }}
            >
              <strong>
                I participated in the bootcamp, focusing on state and selector
                management, implementing a password recovery screen in Angular,
                and using NGRX for frontend state management.
              </strong>
            </p>
            <div className="d-flex align-items-center justify-content-end mt-2">
              <Button
                variant="link"
                className="minimal-button"
                onClick={() => handleShowModal(Certificadocodigo)}
                style={{
                  color: "#1e88e5",
                  fontWeight: "bold",
                  marginLeft: "10px",
                }}
              >
                View Certificate
              </Button>
              <ImageButton
                src={codigoencasa}
                alt="Código en Casa"
                className="img-fluid"
                style={{ width: "35px", borderRadius: "50%" }}
                link="https://codigoencasa.com/bootcamp-real-primera-edicion/"
              />
            </div>
          </VerticalTimelineElement>

          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            date="2022"
            dateClassName="timeline-date"
            iconStyle={{ background: "#1e2a38", color: "#fff" }}
            icon={<FaGraduationCap />}
            contentStyle={{
              border: "1px solid #e0e0e0",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              background: "rgba(249, 249, 249, 0.90)",
            }}
          >
            <h4
              className="vertical-timeline-element-title"
              style={{
                textAlign: "left",
                color: "#1e2a38",
                fontWeight: "600",
              }}
            >
              Certification
            </h4>
            <h5
              className="vertical-timeline-element-subtitle mt-1"
              style={{
                textAlign: "left",
                color: "#3e4c59",
                fontWeight: "500",
              }}
            >
              Egg Live
            </h5>
            <p
              style={{
                textAlign: "left",
                color: "#495a6b",
                fontSize: "0.9rem",
                textWrap: "balance",
              }}
            >
              <strong>
                I acquired a solid understanding of programming fundamentals,
                algorithmic logic, and mathematical reasoning, which provided a
                strong foundation for problem-solving and software development.
              </strong>
            </p>
            <div className="d-flex align-items-center mt-2">
              <Button
                variant="link"
                className="minimal-button"
                onClick={() => handleShowModal(Certificadoegg)}
                style={{
                  color: "#1e88e5",
                  fontWeight: "bold",
                  marginRight: "10px",
                }}
              >
                View Certificate
              </Button>
              <ImageButton
                src={egglive}
                alt="Egg Live"
                className="img-fluid"
                style={{ width: "35px", borderRadius: "50%" }}
                link="https://egg.live/es/home"
              />
            </div>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            iconStyle={{ background: "#1e2a38", color: "#fff" }}
            icon={<FaStar />}
          />
        </VerticalTimeline>

        <ModalCertificate
          show={showModal}
          image={modalImage}
          onClose={handleCloseModal}
        />
      </div>
    </section>
  );
};

export default Timeline;
