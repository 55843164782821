import React, { useState } from "react";
import { sendForm } from "../api/contactApi";
import correoImage from "../assets/correo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { Spinner, Alert } from "react-bootstrap";

const backgroundText = [
  'I need a website to showcase my "creative projects".',
  'Can you develop software to streamline "team workflows"?',
  'Looking for help creating an online store for my "business".',
  'We want to digitize and optimize our "daily operations".',
  'I need a tool to efficiently manage our "inventory".',
  'We’re looking for a mobile app to connect with our "audience".',
  'Can you build a system to track sales and analyze "metrics"?',
  'I need an appointment scheduling system for my "clients".',
  'We want a responsive website optimized for "all devices".',
  'I’m looking for a platform to visualize and share "analytics".',
  'Can you add a secure payment system to our "site"?',
  'I need a CRM to enhance our "customer interactions".',
  'I’m interested in creating a SaaS platform for our "sector".',
  'We need a scheduling tool to coordinate "employee shifts".',
  'I want to improve my website’s SEO to grow "traffic".',
  'We’re looking for an online booking solution for our "services".',
  'Can you create a chatbot to assist customers "24/7"?',
  'We need a marketplace for users to exchange "goods and services".',
  'I’m looking for a secure system for "user logins and data storage".',
  'Can you develop a subscription platform for recurring "users"?',
];

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [status, setStatus] = useState(null);
  const [isSubmitting, setSubmitting] = useState(false);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setSubmitting(true);

    setTimeout(async () => {
      setSubmitting(false);
      setStatus({ type: "success", message: "Message sent successfully!" });
      setFormData({ name: "", email: "", message: "" });
      setTimeout(() => setStatus(null), 3000);

      try {
        await sendForm(
          formData,
          () => {},
          () => {}
        );
      } catch (error) {
        setStatus({
          type: "danger",
          message: "Something went wrong. Please try again.",
        });

        setTimeout(() => setStatus(null), 3000);
      }
    }, 1200);
  };

  return (
    <section
      id="Contact"
      className="py-5"
      style={{
        background: "orange",
        position: "relative",
        overflow: "hidden",
        height: "100vh",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 0,
          color: "rgba(0, 0, 0, 0.1)",
          fontFamily: '"Courier New", monospace',
          fontSize: "3.1rem",
          textAlign: "justify",
          whiteSpace: "pre-wrap",
          pointerEvents: "none",
          lineHeight: "1.5",
        }}
      >
        {backgroundText.join(" ")}
      </div>

      <div
        style={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <div className="container h-100">
          <div className="row h-100 align-items-center">
            <div className="col-12 text-center">
              <div
                style={{
                  position: "relative",
                  width: "fit-content",
                  textAlign: "center",
                  marginBottom: "0.5rem",
                  display: "inline-block",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "orange",
                    filter: "blur(8px)",
                    zIndex: -1,
                  }}
                ></div>
                <h2
                  style={{
                    fontSize: "5.5rem",
                    fontWeight: "700",
                    fontFamily: '"Courier New", monospace',
                    color: "whitesmoke",
                    textTransform: "uppercase",
                    textShadow: `
                  2px 2px 4px rgba(0, 0, 0, 0.7),
                  0 0 10px orange,
                  0 0 15px rgba(255, 165, 0, 0.8),
                  0 0 20px rgba(255, 140, 0, 0.6),
                  0 0 30px rgba(255, 140, 0, 0.5)
                `,
                  }}
                >
                  Let's Connect
                </h2>
              </div>
            </div>

            <div className="col-lg-6 d-none d-lg-flex justify-content-center order-lg-1">
              <img
                src={correoImage}
                alt="Email Illustration"
                className="img-fluid rounded w-75"
                loading="lazy"
                style={{
                  maxHeight: "400px",
                  objectFit: "contain",
                  filter:
                    "invert(25%) sepia(85%) saturate(500%) hue-rotate(190deg)",
                }}
              />
            </div>

            <div className="col-lg-6 col-md-12 order-lg-2 position-relative">
              <form
                onSubmit={(e) => handleSubmit(e, setStatus)}
                className="p-4 bg-light rounded d-flex align-items-start"
                style={{
                  boxShadow: "6px 6px rgba(0, 0, 0, 0.9)",
                  borderRadius: "8px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    fontFamily: '"Courier New", monospace',
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                    color: "#000000",
                    whiteSpace: "pre-line",
                    textAlign: "center",
                    letterSpacing: "1px",
                    marginRight: "20px",
                  }}
                >
                  {"C\nO\nN\nT\nA\nC\nT\n\nM\nE"}
                </div>

                <div style={{ flex: 1, fontWeight: "bold" }}>
                  {status && (
                    <div>
                      <Alert variant={status.type} className="mt-3">
                        {status.message}
                      </Alert>
                    </div>
                  )}
                  {["name", "email", "message"].map((field) => (
                    <div className="mb-3" key={field}>
                      <label htmlFor={field} className="form-label">
                        {field.charAt(0).toUpperCase() + field.slice(1)}
                      </label>
                      {field !== "message" ? (
                        <input
                          type={field === "email" ? "email" : "text"}
                          id={field}
                          value={formData[field]}
                          onChange={handleChange}
                          className="form-control"
                          placeholder={`Your ${field}`}
                          required
                        />
                      ) : (
                        <textarea
                          id={field}
                          value={formData[field]}
                          onChange={handleChange}
                          className="form-control"
                          placeholder="Your message"
                          rows="4"
                          required
                        ></textarea>
                      )}
                    </div>
                  ))}
                  <button
                    type="submit"
                    className="btn btn-primary w-100"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <Spinner animation="border" size="sm" className="me-2" />
                    ) : (
                      <FontAwesomeIcon className="me-2" icon={faPaperPlane} />
                    )}
                    {isSubmitting ? "Sending..." : "Send Message"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
