import React, { useMemo } from "react";
import "../css/style.css";

const HeaderBackground = React.memo(() => {
  const stars = useMemo(
    () =>
      Array.from({ length: 100 }).map((_, i) => {
        const size = Math.random() * 2 + 1;
        const color =
          Math.random() > 0.5
            ? "rgba(255, 255, 255, 0.8)"
            : "rgba(255, 223, 0, 0.8)";
        return (
          <div
            key={`star-${i}`}
            className="header-star"
            style={{
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              width: `${size}px`,
              height: `${size}px`,
              backgroundColor: color,
              animationDelay: `${Math.random() * 0}s`,
            }}
          ></div>
        );
      }),
    []
  );

  const particles = useMemo(
    () =>
      Array.from({ length: 20 }).map((_, i) => {
        const color = `hsl(${Math.random() * 360}, 100%, 70%)`;
        return (
          <div
            key={`particle-${i}`}
            className="header-particle"
            style={{
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              backgroundColor: color,
              animationDelay: `${Math.random() * 2}s`,
            }}
          ></div>
        );
      }),
    []
  );

  const shootingStars = useMemo(
    () =>
      Array.from({ length: 3 }).map((_, i) => (
        <div
          key={`shooting-star-${i}`}
          className="shooting-star"
          style={{
            top: `${Math.random() * 80}%`,
            left: `${Math.random() * 80}%`,
            animationDelay: `${Math.random() * 0}s`,
          }}
        ></div>
      )),
    []
  );

  const satellites = useMemo(
    () =>
      Array.from({ length: 3 }).map((_, i) => (
        <div
          key={`satellite-${i}`}
          className="satellite"
          style={{
            top: `${Math.random() * 100}%`,
            left: `${Math.random() * 100}%`,
            animationDelay: `${Math.random() * 2}s`,
          }}
        >
          🛰️
        </div>
      )),
    []
  );

  const spaceships = useMemo(
    () =>
      Array.from({ length: 3 }).map((_, i) => (
        <div
          key={`spaceship-${i}`}
          className="spaceship"
          style={{
            top: `${Math.random() * 100}%`,
            left: `${Math.random() * 100}%`,
            animationDelay: `${Math.random() * 2}s`,
          }}
        >
          🚀
        </div>
      )),
    []
  );

  return (
    <div className="header-background">
      {[
        ...stars,
        ...particles,
        ...shootingStars,
        ...satellites,
        ...spaceships,
        <div
          key="planet"
          className="planet"
          style={{ top: "0", left: "30%" }}
        ></div>,
        <div
          key="moon"
          className="moon"
          style={{ top: "0", left: "75%" }}
        ></div>,
      ]}
    </div>
  );
});

export default HeaderBackground;
