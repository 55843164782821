import React from "react";

const DownloadCVButton = ({ language = "EN", cvLink }) => {
  const downloadLinks = {
    EN: "https://drive.google.com/file/d/1OlN1T8N5X1FWTG2NzSo_BAU64pZJ1q8h/view?usp=drive_link",
    ES: "https://drive.google.com/file/d/1VfRKdoSnHf6bYYLr2ZI6BgBVuYk4L3ir/view?usp=drive_link",
  };

  return (
    <div style={{ textAlign: "center" }}>
      <a
        href={cvLink || downloadLinks[language]}
        download="Eduardo_Cabral_CV.pdf"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#444",
          color: "#fff",
          textDecoration: "none",
          padding: "10px 20px",
          borderRadius: "5px",
          fontSize: "0.9rem",
          cursor: "pointer",
          margin: "0 auto",
        }}
      >
        <i
          className="fas fa-download"
          style={{ marginRight: "10px", fontSize: "1rem" }}
        ></i>
        Download CV -
        <strong className={language === "EN" ? "text-primary" : "text-danger"}>
          {language}
        </strong>
      </a>
    </div>
  );
};

export default DownloadCVButton;
