import {
  FaReact,
  FaNodeJs,
  FaJs,
  FaCss3Alt,
  FaBootstrap,
  FaHtml5,
} from "react-icons/fa";
import {
  SiExpress,
  SiMongodb,
  SiNetlify,
  SiRender,
  SiCloudflare,
} from "react-icons/si";
import images from "./images";

const proyectos = [
  {
    id: 1,
    titulo: "E-commerce Website",
    descripcion:
      "Online store for formalwear with secure payment, user authentication, and a fully responsive design for all devices.",
    tecnologias: [
      { icon: <FaNodeJs />, color: "#32CD32" },
      { icon: <SiExpress />, color: "#f4f4f4" },
      { icon: <SiMongodb />, color: "#32CD32" },
      { icon: <FaReact />, color: "#61DBFB" },
      { icon: <FaJs />, color: "#f7df1e" },
      { icon: <FaCss3Alt />, color: "#1572b6" },
      { icon: <FaBootstrap />, color: "#7952b3" },
      { icon: <SiNetlify />, color: "#f4f4f4" },
      { icon: <SiRender />, color: "#f4f4f4" },
    ],
    detalles: [
      "Login                  ",
      "Register               ",
      "Recover Password       ",
      "Order History          ",
      "Admin Page (ADM)       ",
      "Deactivate Users (ADM) ",
      "Create (Products)      ",
      "Delete Products        ",
      "Edit Products          ",
      "Payment Preference     ",
      "Mercado Pago           ",
      "Database (Mongodb)     ",
      "Responsive Design      ",
      "Secure Authentication  ",
    ],
    imagenes: images.ecommerce,
    enlace: "https://web-shop-e-commerce.netlify.app/",
  },
  {
    id: 2,
    titulo: "Personal Portfolio",
    descripcion:
      "Personal portfolio with projects, optimized for unique visuals and interactive features to enhance user experience.",
    tecnologias: [
      { icon: <FaReact />, color: "#61DBFB" },
      { icon: <FaJs />, color: "#f7df1e" },
      { icon: <FaCss3Alt />, color: "#1572b6" },
      { icon: <FaBootstrap />, color: "#7952b3" },
      { icon: <SiNetlify />, color: "#f4f4f4" },
    ],
    detalles: [
      "SPA                    ",
      "Interactive Content    ",
      "Responsive Design      ",
      "Smooth Animations      ",
      "Contact Form           ",
      "Project Gallery        ",
      "About Me Section       ",
      "Social Links           ",
      "Resume Download        ",
      "Privacy Policy         ",
      "Fast Loading           ",
      "Modern UI              ",
      "SEO Optimization       ",
    ],
    imagenes: images.eduardo,
    enlace: "https://eduardocabral.netlify.app/",
  },
  {
    id: 3,
    titulo: "Logistics Landing Page",
    descripcion:
      "Transportation landing page with responsive layout, image carousel, and a fully integrated contact form for users.",
    tecnologias: [
      { icon: <FaHtml5 />, color: "#dd1b16" },
      { icon: <FaCss3Alt />, color: "#1572b6" },
      { icon: <FaBootstrap />, color: "#7952b3" },
      { icon: <FaJs />, color: "#f7df1e" },
    ],
    detalles: [
      "Images Carousel        ",
      "Responsive Design      ",
      "Contact Form           ",
      "Information Sections   ",
      "Gallery of Images      ",
      "Service Description    ",
      "Fast Loading           ",
      "Modern UI              ",
      "SEO Optimization       ",
      "Multi-Device Support   ",
      "Domain and Hosting     ",
    ],
    imagenes: images.omega,
    enlace: "https://www.expresoomega.com",
  },
  {
    id: 4,
    titulo: "Graduation Landing Page",
    descripcion:
      "Graduate apparel brand site with custom designs, responsive modals, and dark/light mode toggle for user choice.",
    tecnologias: [
      { icon: <FaHtml5 />, color: "#dd1b16" },
      { icon: <FaCss3Alt />, color: "#1572b6" },
      { icon: <FaBootstrap />, color: "#7952b3" },
      { icon: <FaJs />, color: "#f7df1e" },
    ],
    detalles: [
      "Image Carousel         ",
      "Responsive Design      ",
      "Contact Form           ",
      "Product Gallery        ",
      "Cards with Modals      ",
      "Dark/Light Mode Toggle ",
      "Hamburger Menu         ",
      "Service Description    ",
      "Fast Loading           ",
      "Modern UI              ",
      "SEO Optimization       ",
      "Multi-Device Support   ",
      "Domain and Hosting     ",
    ],
    imagenes: images.roque,
    enlace: "https://www.roqueegresados.com",
  },
  {
    id: 5,
    titulo: "Event Planning Website",
    descripcion:
      "Event planning landing page featuring a gallery, responsive design, modals, and service worker for offline access.",
    tecnologias: [
      { icon: <FaHtml5 />, color: "#dd1b16" },
      { icon: <FaCss3Alt />, color: "#1572b6" },
      { icon: <FaBootstrap />, color: "#7952b3" },
      { icon: <FaJs />, color: "#f7df1e" },
      { icon: <SiCloudflare />, color: "#FFA500" },
    ],
    detalles: [
      "Image Gallery          ",
      "Responsive Design      ",
      "Contact Form           ",
      "Service Worker         ",
      "Cache Optimization     ",
      "Modal Windows          ",
      "Event Services Overview",
      "Fast Loading           ",
      "Modern UI              ",
      "SEO Optimization       ",
      "Multi-Device Support   ",
      "GIF Animations         ",
      "Domain and Hosting     ",
    ],
    imagenes: images.roxana,
    enlace: "https://www.roxanaeventos.com",
  },
  {
    id: 6,
    titulo: "Car Accessories Website",
    descripcion:
      "Minimalist car accessory site with responsive catalog, contact form, and a user-friendly dark/light mode toggle.",
    tecnologias: [
      { icon: <FaHtml5 />, color: "#dd1b16" },
      { icon: <FaCss3Alt />, color: "#1572b6" },
      { icon: <FaBootstrap />, color: "#7952b3" },
      { icon: <FaJs />, color: "#f7df1e" },
    ],
    detalles: [
      "Responsive Design      ",
      "Catalog of Products    ",
      "Contact Form           ",
      "Dark/Light Mode Toggle ",
      "Minimalist Design      ",
      "Product Cards          ",
      "SEO Optimization       ",
      "Fast Loading           ",
      "Modern UI              ",
      "Multi-Device Support   ",
      "Service Description    ",
      "Domain and Hosting     ",
    ],
    imagenes: images.sebacar,
    enlace: "https://www.seba-car.com",
  },
];

export default proyectos;
