import React, { useState } from "react";
import PropTypes from "prop-types";
import "../css/style.css";

const ImageButton = ({ src, alt, link }) => {
  const [hover, setHover] = useState(false);

  return (
    <div
      style={{
        position: "relative",
        display: "inline-block",
        cursor: "pointer",
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <img
        src={src}
        alt={alt}
        className="course-img"
        style={{ width: "35px", borderRadius: "50%" }}
        onClick={() => window.open(link, "_blank", "noopener,noreferrer")}
      />
      {hover && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "110%",
            transform: "translateY(-50%)",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            color: "#fff",
            padding: "6px 10px",
            borderRadius: "8px",
            fontSize: "12px",
            whiteSpace: "nowrap",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
          }}
        >
          View Course
          <div
            style={{
              content: '""',
              position: "absolute",
              top: "50%",
              left: "-4px",
              transform: "translateY(-50%)",
              borderWidth: "6px",
              borderStyle: "solid",
              borderColor:
                "transparent rgba(0, 0, 0, 0.7) transparent transparent",
            }}
          />
        </div>
      )}
    </div>
  );
};

ImageButton.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default ImageButton;
