import React, { useState } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";

const ModalCertificate = ({ show, image, onClose }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <Modal show={show} onHide={onClose} size="lg" centered>
      <Modal.Body className="d-flex justify-content-center align-items-center">
        {!isLoaded && <Spinner animation="border" />}
        <img
          src={image}
          alt="Certificate"
          className="img-fluid"
          style={{
            display: isLoaded ? "block" : "none",
            width: "100%",
            height: "auto",
            objectFit: "contain",
          }}
          onLoad={() => setIsLoaded(true)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalCertificate;
