import React, { useState } from "react";
import { FaInfoCircle } from "react-icons/fa";
import { motion } from "framer-motion";
import { Carousel } from "react-bootstrap";
import InnerImageZoom from "react-inner-image-zoom";
import proyectos from "../utils/proyectos";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.min.css";

const Proyectos = () => {
  const [tooltipVisible, setTooltipVisible] = useState(null);

  const handleMouseEnter = (id) => {
    setTimeout(() => setTooltipVisible(id), 100);
  };

  const handleMouseLeave = () => {
    setTimeout(() => setTooltipVisible(null), 100);
  };

  return (
    <section id="Proyects" className="py-40 text-white">
      <div className="container text-center mt-5">
        <h2
          className="display-2 display-sm-5 display-md-6 display-lg-3"
          style={{
            fontFamily: '"Courier New", monospace',
            fontWeight: "700",
            color: "white",
            marginBottom: "0.5rem",
          }}
        >
          My Works & my Projects
        </h2>
        <h3
          className="display-sm-4 display-md-5 display-lg-2"
          style={{
            background:
              "linear-gradient(to right, #FF7F50, #FFD700, #32CD32, #1E90FF, #8A2BE2)",
            WebkitBackgroundClip: "text",
            color: "transparent",
            fontWeight: "bold",
            marginBottom: "3rem",
          }}
        >
          Here are some of my recent projects & works.
        </h3>
        <div
          className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4"
          style={{ overflow: "visible", position: "relative", zIndex: 0 }}
        >
          {proyectos.map((proyecto) => (
            <motion.div
              key={proyecto.id}
              className="col"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{
                duration: 0.4,
                ease: "easeOut",
                delay: proyecto.id * 0.2,
              }}
            >
              <div
                className="card shadow-sm rounded-3 position-relative"
                style={{
                  background: "#1d1d1d",
                  opacity: "90%",
                  color: "#fff",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  zIndex: 1,
                  overflow: "visible",
                }}
              >
                <Carousel
                  fade
                  interval={null}
                  indicators={false}
                  controls={true}
                  nextLabel=""
                  prevLabel=""
                  style={{
                    position: "relative",
                    height: "240px",
                  }}
                >
                  {proyecto.imagenes.map((img, index) => (
                    <Carousel.Item key={index}>
                      <InnerImageZoom
                        loading="lazy"
                        src={img}
                        zoomSrc={img}
                        zoomType="click"
                        zoomPreload={true}
                        zoomScale={0.6}
                        alt={`Imagen ${index + 1} del proyecto ${
                          proyecto.titulo
                        }`}
                        style={{
                          height: "255px",
                          objectFit: "cover",
                          borderRadius: "0.25rem",
                        }}
                      />
                    </Carousel.Item>
                  ))}
                </Carousel>
                <div className="card-body text-center">
                  <h5
                    className="card-title mb-3 d-flex justify-content-center align-items-center"
                    style={{ color: "#0dcaf0" }}
                  >
                    {proyecto.titulo}
                    <div
                      style={{ position: "relative" }}
                      onMouseEnter={() => handleMouseEnter(proyecto.id)}
                      onMouseLeave={handleMouseLeave}
                    >
                      <button
                        type="button"
                        aria-label={`Más información sobre ${proyecto.titulo}`}
                        style={{
                          background: "transparent",
                          border: "none",
                          borderRadius: "50%",
                          padding: "8px",
                          cursor: "pointer",
                        }}
                      >
                        <FaInfoCircle
                          style={{ color: "#f4f4f4", fontSize: "15px" }}
                        />
                      </button>
                      {tooltipVisible === proyecto.id && (
                        <motion.div
                          initial={{ opacity: 0, scale: 0.9 }}
                          animate={{ opacity: 1, scale: 1 }}
                          transition={{ duration: 0.3 }}
                          className="tooltip-custom"
                          style={{
                            position: "absolute",
                            bottom: "calc(100% + 10px)",
                            left: "-500%",
                            transform: "translateX(-50%)",
                            zIndex: 9999,
                            backgroundColor: "rgba(0, 0, 0, 0.9)",
                            color: "white",
                            padding: "10px",
                            borderRadius: "5px",
                            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)",
                            maxWidth: "500px",
                            width: "auto",
                            textAlign: "left",
                          }}
                        >
                          <ul
                            className="m-0 p-0"
                            style={{
                              listStyle: "none",
                              fontSize: "10px",
                            }}
                          >
                            {proyecto.detalles.map((detalle, idx) => (
                              <li key={idx} className="mb-1">
                                <span
                                  style={{
                                    backgroundColor: "#32CD32",
                                    color: "black",
                                    borderRadius: "50%",
                                    width: "15px",
                                    height: "15px",
                                    display: "inline-flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    fontSize: "11px",
                                    marginRight: "8px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  ✓
                                </span>
                                {detalle}
                              </li>
                            ))}
                          </ul>
                        </motion.div>
                      )}
                    </div>
                  </h5>
                  <p
                    style={{
                      fontSize: "14px",
                      color: "#b0b0b0",
                      textWrap: "balance",
                    }}
                  >
                    {proyecto.descripcion}
                  </p>
                  <div className="d-flex justify-content-center gap-3 mb-3">
                    {proyecto.tecnologias.map((tech, idx) => (
                      <span
                        key={idx}
                        style={{ color: tech.color, fontSize: "20px" }}
                        aria-label={`Tecnología utilizada: ${tech.icon.type.name}`}
                      >
                        {tech.icon}
                      </span>
                    ))}
                  </div>
                  <a
                    href={proyecto.enlace}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn"
                    style={{
                      fontSize: "14px",
                      padding: "8px 16px",
                      borderRadius: "16px",
                      backgroundColor: "#007bff",
                      color: "#f4f4f4",
                      textDecoration: "none",
                      display: "inline-flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "8px",
                      transition: "background-color 0.3s ease",
                      fontWeight: "500",
                      lineHeight: "1",
                    }}
                    aria-label={`Ir al proyecto: ${proyecto.titulo}`}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.backgroundColor = "#0056b3";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.backgroundColor = "#007bff";
                    }}
                  >
                    <span style={{ pointerEvents: "none" }}>Deploy</span>
                    <span style={{ pointerEvents: "none" }}>&rarr;</span>
                  </a>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Proyectos;
